import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, ImageLeft, LEADTEXT} from "../../styles/Global";
import ReadMore from "../ReadMore";
import {Row, Col} from "antd";
import {StaticImage} from "gatsby-plugin-image";

const NoLimit = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 64}}>
          <Col xs={24} sm={10} md={10} lg={12} xl={12}>
            <ImageLeft>
              <StaticImage
                src={"../../images/aerial_cinestar.jpg"}
                layout="constrained"
                alt="aerial cinestar drone"
                loading="eager"
                placeholder="blurred"
              />
            </ImageLeft>
          </Col>
          <Col xs={24} sm={14} md={14} lg={12} xl={12}>
            <Title>Sky is the limit.</Title>
            <Paragraph>
              Drones have gone from a novelty to being highly capable aerial platforms that can cover a vast variety
              of fields. We have a wide range of different types of drones to handle whatever you may need. From
              our 5-inch FPV drones to our cinema-ready heavy lift octo-copter, all our drones are ready to take
              to the skies for your next project.
            </Paragraph>
            <ReadMore>Learn More</ReadMore>
          </Col>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(NoLimit);

const Wrapper = styled(Section)`
  background: rgba(241, 240, 245, 1);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled(Row)`
  align-items: center;
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
  opacity: 1;
`;
