import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, ImageRight, LEADTEXT} from "../../styles/Global";
import ReadMore from "../ReadMore";
import {Row, Col} from "antd";
import {StaticImage} from "gatsby-plugin-image";

const FirstResponders = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 64}}>
          <Col xs={24} sm={14} md={14} lg={12} xl={12}>
            <Title>First Responders Assistance.</Title>
            <Paragraph>
              Emergency situations require access to information as quickly as possible. JL Tech’s drones offer a
              cost-effective way for first responders to get the information they need. Eyes in the sky are often
              a firefighter’s best tool for finding flareups, as well as spotting areas that are prone to fire.
              Search and rescue operations can use drones to quickly find lost persons in large areas and rough terrain.
            </Paragraph>
            <ReadMore>Learn More</ReadMore>
          </Col>
          <Col xs={24} sm={10} md={10} lg={12} xl={12}>
            <ImageRight>
              <StaticImage
                src={"../../images/aerial_production.jpg"}
                layout="constrained"
                alt="aerial cinestar drone"
                loading="eager"
                placeholder="blurred"
              />
            </ImageRight>
          </Col>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(FirstResponders);

const Wrapper = styled(Section)`
  background: rgba(26, 18, 88, 1);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled(Row)`
  align-items: center;
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
`;
