import React, {forwardRef} from "react";
import { Logo } from "../Logo";
import Section from "../section";
import styled from "styled-components";
import {BREAKPOINTS} from "../../constants";
import {CONTAINER, D1, LogoWrapper} from "../../styles/Global";

const Header = (props, ref) => {
  return (
    <Section {...props} ref={ref}>
      <Container>
        <Content>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <Title>Aerial<br/>Services</Title>
        </Content>
      </Container>
    </Section>
  );
};

export default forwardRef(Header);

const Container = styled.div`
  ${CONTAINER}
  padding: 0 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    padding: 0 20px;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  ${D1}
  text-align: center;
  font-weight: bold ;
  font-family: var(--unnamed-font-family-proxima-nova);
  letter-spacing: 0px;
  color: #f1f0f5;
  text-transform: uppercase;
  opacity: 1;
  padding-bottom: 30px;
`;