import React from "react";
import Header from "./Header";
import Layout from '../Layout'
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
import NoLimit from "./NoLimit";
import Production from "./Production";
import Inspections from "./Inspections";
import FirstResponders from "./FirstResponders";
import RealEstate from "./RealEstate";
import Certified from "./Certified";
import HorizontalScroll from "../HorizontalScroll";
import Contact from "../contact";

const Index = () => {

  const underlay = () => {
    return (
      <>
        <Background>
          <StaticImage
            src="../../images/aerial_hero@2x.jpg"
            alt="Aerial or Drone Services"
            loading="eager"
          />
        </Background>
        <Foreground />
      </>
    );
  }

  return (
    <Layout underlay={underlay()} metatags={{
      title: "JLTech Aerial - Cinema, First Responders, Inspections & Real Estate",
      desc: `Drones have gone from novelty, to being highly capable aerial platforms that can cover a vast variety of 
      fields. We have a wide range of different types of platforms to handle whatever you may need. Whether it be our 
      cinema-ready heavy lift octo-copter, or our 5-inch FPV drones, they are ready to take to the skies for
      our next project.`,
      keywords: `certified drone pilots near me, aerial inspection coeur d'alene, aerial inspection spokane, firefighting with drones, 
        real estate photography, aerial cinema photography, aerial inspections, drones cda, drones coeur d'alene, drones spokane, jltech, cda`
    }} snap>
      <Header id="header" />
      <NoLimit id="no-limit" />
      <HorizontalScroll id="aerial-services">
        <Production id="production" />
        <Inspections id="inspections"  />
        <FirstResponders id="first-responders" />
        <RealEstate id="real-estate" />
      </HorizontalScroll>
      <Certified id="faa-certified" />
      <Chat />
    </Layout>
  );
};

export default Index;

const Chat = styled(Contact)`
  height: auto;
  padding: 100px 0;
`;

const Background = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, #412ddb 0%, #3a28c500 100%) 0% 0% no-repeat padding-box;
`;