import React from 'react'
import Index from '../components/aerial'

 const aerial = (props) => {
    return (
        <>
            <Index />
        </>
    )
}
export default aerial;