import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, ImageLeft, LEADTEXT} from "../../styles/Global";
import ReadMore from "../ReadMore";
import {Row, Col} from "antd";
import {StaticImage} from "gatsby-plugin-image";

const RealEstate = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 64}}>
          <Col xs={24} sm={10} md={10} lg={12} xl={12}>
            <ImageLeft>
              <StaticImage
                src={"../../images/aerial_realestate.jpg"}
                layout="constrained"
                alt="various drones for realestate photography"
                loading="eager"
                placeholder="blurred"
              />
            </ImageLeft>
          </Col>
          <Col xs={24} sm={14} md={14} lg={12} xl={12}>
            <Title>Real Estate.</Title>
            <Paragraph>
              Alongside our real estate photography services, we also provide aerial photography. Take your listings
              to a new height with photos that capture a whole new perspective. Looking for something totally unique?
              Our FPV pilots can do interior/exterior drone tours with our miniature drones for a new take on real
              estate videography. Additionally, we can do neighborhood tours to encourage even more sales.
            </Paragraph>
            <ReadMore to="https://adobe.ly/3nJPHjL" target="_blank" external>View Demo</ReadMore>
          </Col>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(RealEstate);

const Wrapper = styled(Section)`
  background: rgba(241, 240, 245, 1);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled(Row)`
  align-items: center;
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
  opacity: 1;
`;
