import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, ImageRight, LEADTEXT} from "../../styles/Global";
import ReadMore from "../ReadMore";
import {Row, Col} from "antd";
import {StaticImage} from "gatsby-plugin-image";

const Certified = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 64}}>
          <Col xs={24} sm={14} md={14} lg={12} xl={12}>
            <Title>FAA Certified.</Title>
            <Paragraph>
              All our pilots are FAA Part 107 Certified for commercial drone operations. As such, they are trained
              on proper airspace usage, safety techniques, and flight guidelines that keep everything in check.
              This training, alongside their time spent in flight simulators and regular flight practice make our
              pilots highly capable of whatever mission you need flown. Your imagination is the limit to
              our capabilities.
            </Paragraph>
          </Col>
          <Col xs={24} sm={10} md={10} lg={12} xl={12}>
            <Image>
              <StaticImage
                src={"../../images/faa-part-107-compliant.png"}
                layout="constrained"
                alt="federal aviation administration part 107 compliant"
                loading="eager"
                placeholder="blurred"
                aspectRatio="1.75"
                backgroundColor="transparent"
                transformOptions={{fit: "contain", cropFocus: "entropy"}}
              />
            </Image>
          </Col>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Certified);

const Wrapper = styled(Section)`
  background: rgba(241, 240, 245, 1);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled(Row)`
  align-items: center;
`;

const Image = styled(ImageRight)`
  height: 40vh;
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
  opacity: 1;
`;
