import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, ImageRight, LEADTEXT} from "../../styles/Global";
import ReadMore from "../ReadMore";
import {Row, Col} from "antd";
import {StaticImage} from "gatsby-plugin-image";

const Inspections = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 64}}>
          <Col xs={24} sm={14} md={14} lg={12} xl={12}>
            <Title>Aerial Inspections.</Title>
            <Paragraph>
              JL Tech can also handle aerial inspections. Checking cell towers and other structures for damage with
              traditional methods introduces risk to personnel, as well as taking considerable amounts of time.
              Aerial inspections eliminate risk to your employees, while also gathering critical information much
              more quickly.
            </Paragraph>
            <ReadMore>Learn More</ReadMore>
          </Col>
          <Col xs={24} sm={10} md={10} lg={12} xl={12}>
            <ImageRight>
              <StaticImage
                src={"../../images/aerial_inspection.jpg"}
                layout="constrained"
                alt="drones for aerial inspections"
                loading="eager"
                placeholder="blurred"
              />
            </ImageRight>
          </Col>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Inspections);

const Wrapper = styled(Section)`
  background: rgba(65, 45, 219, 1);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled(Row)`
  align-items: center;
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
`;
