import React, {forwardRef} from "react";
import styled from "styled-components";

const HorizontalScroll = ({children, ...props}, ref) => {
  return (
    <Wrapper id="horizontal-scroll" {...props} data-scrollx="true" ref={ref}>
      <Content>
        {children.map((child, index) => {
          return React.cloneElement(child, {key: index});
        })}
      </Content>
    </Wrapper>
  );
};

export default forwardRef(HorizontalScroll);

const PaddingElement = ({width, height, ...props}, ref) => <Pad id="scroll-padding" {...props} ref={ref} />;
export const ScrollPadding = forwardRef(PaddingElement);

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  background: transparent;
  display: flex;
  flex-direction: row;
`;

const Pad = styled(({width, height, ...props}) => <div {...props} />)`
  position: relative;
  height: ${({height}) => height ? height : '100vh'};
  width: ${({width}) => width ? width : '100vw'};
  min-width: 100%;
  overflow: hidden;
`;